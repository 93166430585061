<template>
  <VLayout :class="['menu-layout', { isDirty }]" row wrap>
    <BaseMenuComponent
      v-if="type !== 'default'"
      :name="`MenuLayout${type}`"
      :menu="menu"
      :my-mods="myMods"
    />
    <template v-else>
      <VFlex
        :xs12="!hasLegend || ['xs', 'sm', 'md'].includes($mq)"
        :xs6="hasLegend && !['xs', 'sm', 'md'].includes($mq)"
      >
        <BaseEditable
          v-if="menu.title"
          tag="h2"
          :content="menu.title"
          :mods="myMods.title"
          class="menu-layout--title text-sm-center header-4"
          @update="updateMenu('title', $event)"
          @dirtyUpdate="adjustDirtScore"
        />
      </VFlex>
      <VFlex v-if="hasLegend" pl-3 sm6 hidden-sm-and-down>
        <MenuLegend />
      </VFlex>
      <VContainer v-if="menu.sections && menu.sections.length" fluid pa-0>
        <VLayout row wrap class="menu-sections">
          <template v-for="(sectionId, i) of sectionIds">
            <WithMenuSection
              :id="sectionId"
              v-slot="{
                isSectionLoading,
                section,
                itemMods,
                myMods: sectionMods,
              }"
              :key="sectionId"
            >
              <VFlex xs12 sm6 lg4 pa-2 class="menu-section--wrap">
                <BaseMenuComponent
                  :key="sectionId"
                  :ref="`section-${i}`"
                  name="MenuSection"
                  :entity="section"
                  :item-mods="itemMods"
                  :my-mods="sectionMods"
                  @addSectionAfter="
                    addSectionAction({ parentId: menu.id, index: i + 1 })
                  "
                  @deleteSection="
                    removeSection({ parentId: menu.id, sectionId })
                  "
                  @dirtyUpdate="adjustDirtScore"
                />
              </VFlex>
            </WithMenuSection>
          </template>
        </VLayout>
      </VContainer>
      <VFlex v-if="hasLegend" xs12 hidden-md-and-up>
        <MenuLegend />
      </VFlex>
    </template>
  </VLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import selectElementText from '@utils/select-element-text'

import MenuLegend from '@menu/MenuLegend'
import MenuLayoutMixin from '@mixins/menu-layout'

export default {
  name: 'BubsMenuLayout',
  components: {
    MenuLegend,
  },
  mixins: [MenuLayoutMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters('entities', [
      'getEntitySet',
      'getEntityMods',
      'getNonRemovedEntitySet',
    ]),
    myMods() {
      return this.getEntityMods('menu', this.menu.id)
    }, // myMods
  },
  methods: {
    ...mapActions('menus', ['tryMenuMod']),
    ...mapActions('sections', ['stageNewSection', 'removeSection']),

    updateMenu(key, val) {
      this.tryMenuMod({
        entityName: 'menu',
        entityId: this.menu.id,
        mods: { [key]: val },
      })
    }, // updateMenu

    async addSectionAction({ parentId, index }) {
      await this.stageNewSection({ parentId, index })
      let newSectionTitleEl = this.$refs[`section-${index}`][0].$refs['title']
        .$el
      newSectionTitleEl.focus()
      selectElementText(newSectionTitleEl)
    }, // addSectionAfterAction
  },
}
</script>

<style lang="scss">
@import '@design';

.menu-layout {
  &.isDirty {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: purple;
      transform: translateY(-200%);
    }
  }
}

.menu-sections {
  // TODO (ESS) : don't hardcode this.
  // this is to account for the px-3 applied on MenuSection
  margin-right: -16px;
  margin-left: -16px;
}
</style>
